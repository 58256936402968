<div class="grid-display">
    <div *ngIf="!isInCertifyMode" class="header-section">
        <h3 class="header-text">
            Daily Logs
        </h3>
        <app-add-driver-event-button></app-add-driver-event-button>
    </div>
    <div class="header-section control-box">
        <app-date-control [isInCertifyMode]="isInCertifyMode"></app-date-control>
        <div class="arrange-info" [ngClass]="{'arrange-info-cert': !isInCertifyMode}">
            <div *ngIf="!isInCertifyMode" class="notification-icon-container line-up">
                <div *ngIf="certificationStatus; else uncertified" [ngStyle]="{display: isDisabled ? 'none' : ''}">
                    <div class="notification">
                        <i class="material-icons certified notification-icon">check_circle</i>
                        <span class="certified notification-text">CERTIFIED</span>
                    </div>
                </div>
                <ng-template #uncertified>
                    <div class="notification">
                        <zui-icon icon="warning" class="material-icons uncertified notification-icon" inline="true"></zui-icon>
                        <span class="uncertified notification-text">UNCERTIFIED</span>
                    </div>
                </ng-template>
            </div>
            <div class="line-up arrange-info">
                <app-distance-toggle [isDisabled]="isDisabled"></app-distance-toggle>
            </div>
        </div>
    </div>
    <div class="grid-container" (click)="clickGrid()">
      <zui-hos-grid
        [driverTimeZone]="driverTimeZone"
        [name]="'hos-grid'"
        [gridEvents]="gridEvents"
        [dutyStatusTotals]="dutyStatusTotals"
        [day]="day"
      ></zui-hos-grid>
    </div>
</div>
